import React, { useEffect, useState } from "react";
import url from "url";
import {ApolloProvider} from "react-apollo";
import ApolloClient from "apollo-client";
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import {Helmet} from "react-helmet";
import fetch from "isomorphic-fetch";
import config from "../config";
import "../style/index.scss";
const cache = new InMemoryCache();

export default function Template(props) {
  const link = new HttpLink({
    credentials: "include",
    uri: `${config.apiUrl}/graphql.api`,
    fetch: fetch,
  });
  const client = new ApolloClient({
    cache,
    link,
  });

  return (
    <ApolloProvider client={client}>
      <Helmet>
        <title>{`${config.titlePrefix} ${props.title}`}</title>
        <link rel="stylesheet" href="/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossOrigin="anonymous"/>
        <link rel="stylesheet" href="/fontawesome/css/fontawesome.min.css" />
        <link rel="stylesheet" href="/fontawesome/css/all.min.css" />
        {/* <link rel="stylesheet" href={`/theme.css`} /> */}
        <link rel="stylesheet" href={`/heveltica_font.css`} />
        {props.head}
      </Helmet>
      {props.children}
    </ApolloProvider>
  );
}